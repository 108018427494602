import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: process.env.SENTRY_DSN,

  integrations: [
		new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/\/api\/v4-2\//],
    }),
		new Sentry.Replay()
	],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});
